import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { LoadingService } from "src/app/shared/feddback/loading.service";
import { ToastComponent } from "src/app/shared/feddback/toast/toast.component";
import { environment } from "src/environments/environment";
import { profile_info } from '../../utils/api/calls';

@Injectable({
  providedIn: 'root'
})
export class AlunoService {

  constructor(private http: HttpClient,
    private router: Router,
    private toast: ToastComponent,
    public loading: LoadingService) { }

    getAlunoInfo(){
      return this.http.get(`${environment.API_BASE_URL}` + profile_info).pipe(
          catchError(error => {
              if (error.status === 401){
                  this.loading.dismiss();
                  this.toast.presentToast('Sessão expirada!');
                  this.router.navigate(['/login']);
                  localStorage.setItem("menu", "1")
              }
              return throwError(error);
          })
      );
  }

}