import { Component, OnInit } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit {

  constructor(public toastController: ToastController) { }

  ngOnInit() {}

  async presentToast(text: string, tipo?: string) {
    if(!tipo){
      tipo = 'danger'
    }
    const toast = await this.toastController.create({
      message: text,
      duration: 2000,
      color: tipo
    });
    toast.present();
  }

}
