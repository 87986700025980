import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AuthGuard} from './utils/guard/auth-guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'splash',
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./modules/folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'esqueci-minha-senha',
    loadChildren: () => import('./modules/esqueci-minha-senha/esqueci-minha-senha.module').then( m => m.EsqueciMinhaSenhaPageModule)
  },
  {
    path: 'splash',
    loadChildren: () => import('./modules/splash/splash.module').then( m => m.SplashPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./modules/faq/faq.module').then( m => m.FaqPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'perfil',
    loadChildren: () => import('./modules/perfil/perfil.module').then( m => m.PerfilPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'provas-agendadas',
    loadChildren: () => import('./modules/provas/provas-agendadas/provas-agendadas.module').then( m => m.ProvasAgendadasPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'aplicacao-da-prova/:id',
    loadChildren: () => import('./modules/provas/aplicacao-da-prova/aplicacao-da-prova.module').then( m => m.AplicacaoDaProvaPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'provas-em-correcao',
    loadChildren: () => import('./modules/provas/provas-em-correcao/provas-em-correcao.module').then( m => m.ProvasEmCorrecaoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'meus-resultados',
    loadChildren: () => import('./modules/resultados/meus-resultados/meus-resultados.module').then( m => m.MeusResultadosPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'resultado-disciplina',
    loadChildren: () => import('./modules/resultados/resultado-disciplina/resultado-disciplina.module').then( m => m.ResultadoDisciplinaPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'resultado-prova/:id',
    loadChildren: () => import('./modules/resultados/resultado-prova/resultado-prova.module').then( m => m.ResultadoProvaPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'primeiro-acesso',
    loadChildren: () => import('./modules/primeiro-acesso/primeiro-acesso.module').then( m => m.PrimeiroAcessoPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./modules/home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'contato',
    loadChildren: () => import('./modules/contato/contato.module').then( m => m.ContatoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'instrucao-da-prova',
    loadChildren: () => import('./shared/modals/instrucao-da-prova/instrucao-da-prova.module').then( m => m.InstrucaoDaProvaPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'error-modal',
    loadChildren: () => import('./shared/modals/error-modal/error-modal.module').then( m => m.ErrorModalModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'questoes-a-responder',
    loadChildren: () => import('./shared/modals/questoes-a-responder/questoes-a-responder.module').then( m => m.QuestoesAResponderPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'prova-enviada',
    loadChildren: () => import('./modules/provas/prova-enviada/prova-enviada.module').then( m => m.ProvaEnviadaPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modal-data',
    loadChildren: () => import('./shared/modals/modal-data/modal-data.module').then( m => m.ModalDataPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modal-disciplina',
    loadChildren: () => import('./shared/modals/modal-disciplina/modal-disciplina.module').then( m => m.ModalDisciplinaPageModule),
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
