import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { UserAuth } from '../../models/login-model/UserAuth';
import { ToastComponent } from '../../shared/feddback/toast/toast.component';
import { LocalStorageService } from '../../utils/storage/local-storage.service';
import { LoadingService } from '../../shared/feddback/loading.service';
import { login_check } from '../../utils/api/calls';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient,
    private router: Router,
    private toast: ToastComponent,
    public loading: LoadingService,
    private localStorageService: LocalStorageService) { }



  loginExecute(credencias) {
    return this.http.post<UserAuth>(`${environment.API_BASE_URL}` + login_check, credencias).pipe(
      catchError(error => {
        if (error.status === 401) {
          this.loading.dismiss();
          this.toast.presentToast('Credenciais inválidas!');
        }
        return throwError(error);
      })
    );

  }

  isLoggin(): boolean {

    if (!this.localStorageService.get('token')) {
      return false;
    }

    return true;

  }

}
