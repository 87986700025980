import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import {environment} from '../../../environments/environment';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {LoadingService} from '../../shared/feddback/loading.service';
import { LocalStorageService } from "src/app/utils/storage/local-storage.service";
import {ToastComponent} from '../../shared/feddback/toast/toast.component';
import {Prova, SendProva} from '../../models/prova-model/prova.model';
import { get_prova, get_resultado_prova, scheduled_lists, send_prova, list_student_test, status_prova_aluno } from '../../utils/api/calls';

@Injectable({
  providedIn: 'root'
})
export class ProvaService {

  constructor(private http: HttpClient,
              private router: Router,
              private toast: ToastComponent,
              private localStorageService: LocalStorageService,
              public loading: LoadingService) { }

  getProva(id: string){
    return this.http.get(`${environment.API_BASE_URL}` + get_prova + id).pipe(
          catchError(error => {
              if (error.status === 401){
                  this.loading.dismiss();
                  this.toast.presentToast('Sessão expirada!');
                  this.router.navigate(['/login']);
              }
              return throwError(error);
          })
      );
  }

  getResultadoProva(id: string){
    return this.http.get(`${environment.API_BASE_URL}` + get_resultado_prova + id + '/' + this.localStorageService.get("idAluno")).pipe(
          catchError(error => {
              if (error.status === 401){
                  this.loading.dismiss();
                  this.toast.presentToast('Sessão expirada!');
                  this.router.navigate(['/login']);
              }
              return throwError(error);
          })
      );
  }



  listAgendadas() {

   return this.http.get(`${environment.API_BASE_URL}`+ scheduled_lists).pipe(
        catchError(error => {
          if (error.status === 401){
            this.loading.dismiss();
            this.toast.presentToast('Sessão expirada!');
            this.router.navigate(['/login']);
          }
          return throwError(error);
        })
    );
  }

  listProvaAluno(id){
    return this.http.get(`${environment.API_BASE_URL}`+ list_student_test + id).pipe(
      catchError(error => {
        if (error.status === 401){
          this.loading.dismiss();
          this.toast.presentToast('Sessão expirada!');
          this.router.navigate(['/login']);
        }
        return throwError(error);
      })
  );
  }


  sendProva(prova){
    return this.http.post<SendProva>(`${environment.API_BASE_URL}` + send_prova, prova).pipe(
      catchError(error => {
        if (error.status === 401) {
          this.loading.dismiss();
          this.toast.presentToast('Ocorreu um erro!');
        }
        this.toast.presentToast('Ocorreu um erro!');
        return throwError(error);
      })
    );
  }

  sendProvaStatus(object){
    return this.http.post(`${environment.API_BASE_URL}` + status_prova_aluno, object).pipe(
        catchError(error => {
            if (error.status === 401){
                this.loading.dismiss();
                this.toast.presentToast('Sessão expirada!');
                this.router.navigate(['/login']);
            }
            return throwError(error);
        })
    );
}
}
